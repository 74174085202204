import React, { useState, useEffect, useRef } from 'react';
import imageNYC from './images/Atoms/gandolf.jpg';

const Dropdown = ({ buttonName, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        {buttonName}
        <p className= "plus">
        +
        </p>
      </button>

      {isOpen && (
        <ul className="dropdown-menu">
          {options.map((option) => (
            <li className="label" key={option.label}>
              {option.label}
              {option.imgSrc && <img src={option.imgSrc} alt={option.label} />}
              {option.subBullets && (
                <ul>
                  {option.subBullets.map((subBullet) => (
                    <li className="subBullet" key={subBullet} dangerouslySetInnerHTML={{ __html: subBullet }}></li>

                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const App = () => {
  const options1 = [
    {
      label: 'Building Something Old',
      subBullets: ['A <a href=https://buildingsomethingold.substack.com/?r=ky9we&utm_campaign=pub&utm_medium=web> newsletter </a> about how technology is changing traditionally antiquated industries.','As seen in Tyler Cowen\'s <a href= https://marginalrevolution.com/marginalrevolution/2023/10/tuesday-assorted-links-436.html#comments> Marginal Revolution </a>','Topics include Stripe Press, Construction Tech, Startup Cities, Insurtech, Govtech, etc.'],
    },
    {
      label: 'Contrary Research Fellowship',
      subBullets: ['<a href=https://contrary.com/> Contrary</a> is a venture fund backed by the worlds most iconic entrepreneurs including founders of Tesla, Reddit, Facebook, Twitch + more.',
      'Contrary is building a database of research to be the best starting point to understand any private tech company.',
      'I research and publish reports on the most impactful private tech companies, with a focus on antiquated/broken markets. Some examples: <a href= https://research.contrary.com/reports/vannevar-labs> Vannevar Labs </a>, <a href= https://research.contrary.com/reports/vts> VTS </a>, <a href=https://research.contrary.com/reports/kalshi> Kalshi <a/>, and <a href=https://research.contrary.com/reports/homebound> Homebound </a>.'],
        },
    {
      label: 'Noted',
      subBullets: ['A book club, but for writing.', '<a href=https://noted.beehiiv.com/> Noted </a> is a group newsletter where I am a contributor writing about tech, startups and venture capital.'],
    },
    {
      label: 'The Rise of Alternative Early Stage Financing Methods',
      subBullets: ['My undergraduate honors program <a href=https://opencommons.uconn.edu/srhonors_theses/772/> thesis </a> that outlines innovations in the early stage financing environment + analyzes the impact of varying financing instruments on startup outcomes.'],
    },
    {
      label: 'The Shareholder V. Stakeholder Contrast, a Brief History',
      subBullets: ['A one off economics article on Stakeholder vs. Shareholder primacy. This <a href=https://medium.com/@tylerlasicki/the-shareholder-v-stakeholder-contrast-a-brief-history-c5a6cfcaa111> article </a> was featured in Medium publication The Startup.'],
        }
    ];
  const options2 = [
      {
      label: 'Coasters (2021-Present)',
      subBullets: ['Currently leading Product and Ops for <a href = https://coasters.co/> Coasters </a>, a startup incubated out of Loeb.nyc that is building an infrastructure layer for alcohol ecommerce.', 'Projects led across integrations, onboarding, ecommerce checkouts, payment architecture, landing pages, internal tools, and data feeds.'],
                  },
      {
       label: 'Strata (2021)',
       subBullets: ['Contracted as a Product Manager to manage the requirements gathering, scoping, and wire framing process for a note taking app for a macro analytics and research firm while in Undergrad @ UConn.',
       '<iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FR9GyBHtMvZmUbh8Qgvh5Au%2FVentureWerx%3Fkind%3D%26node-id%3D1-10%26scaling%3Dcontain" allowfullscreen></iframe>'],
            },
      {
        label: 'Robin (2020-2021)',
        subBullets: ['An SMB data analytics platform to minimize food waste. Launched with a single bagel shop in CT and participated in startup accelerator, Accelerate UConn.',
        '<iframe src="https://player.vimeo.com/video/821495212?h=4f8ebfbdbe&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Robin Demo"></iframe>'
      ],
      },
      {
        label: 'remembr. (2017-2018)',
        subBullets: ['A memory sharing social platform built to make social media more authentic. Beta tested with ~100 users.'],
      }
      ];



      const options3 = [
            {

             subBullets: ['<iframe title="vimeo-player" src="https://player.vimeo.com/video/802501150?h=5d335a807b" width="640" height="360" frameborder="0"allowfullscreen></iframe>'],
                  },
            {

              subBullets: [ '<iframe src="https://player.vimeo.com/video/204485319?h=7ebe6cc364" width="640" height="338" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe><p><a href="https://vimeo.com/204485319"></a> '],
            },
            {
              subBullets: ['<iframe title="vimeo-player" src="https://player.vimeo.com/video/249867175?h=5d067d9deb" width="640" height="360" frameborder="0"    allowfullscreen></iframe>'],
            },
            {
              subBullets: ['<iframe title="vimeo-player" src="https://player.vimeo.com/video/220459931?h=f26b677695" width="640" height="360" frameborder="0"    allowfullscreen></iframe>'],
            },
            ];

            const options4 = [
                        {
                         label: 'Pods I\'m Listening to',
                         subBullets: ['Business Breakdowns,Founders, Lennys Podcast, Upstream, In the Arena, Beneath The Surface, The Frontier, All In, The Logan Bartlet Show, How I Built This',
                         '<iframe style="border-radius:12px" src="https://open.spotify.com/embed/show/2IqXAVFR4e0Bmyjsdc8QzF/video?utm_source=generator&theme=0" width="624" height="351" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> <iframe style="border-radius:12px" src="https://open.spotify.com/embed/show/6qmUIJQkuEeyJveFZtrNcl?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe><iframe style="border-radius:12px" src="https://open.spotify.com/embed/show/417NPBWqtMbDU0FlWZTRDC?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>'],
                              },
                        {
                          label: 'Newsletters I\'m reading',
                          subBullets: ['Benedicts Newsletter, The Map is Mostly Water, Noahpinion, Not Boring,The Free Press, Construction Physics, Gettingclinical'],
                        },
                        {
                        label: 'Websites I Admire',
                        subBullets: ['<a href= https://poolsuite.net/> Pool Suite </a>, <a href= https://zach.dev/> Zach.dev </a>, <a href= https://desktop.pacecapital.com/> Pace Capital </a>, <a href=https://press.stripe.com/> Stripe Press </a>, <a href= https://www.hash3.xyz/> hash3 </a>, <a href= https://www.ben-evans.com/> Benadict Evans </a>, <a href= https://www.berkshirehathaway.com/> Berkshire Hathaway <a/>'],
                                                },
                        {
                         label: 'Favorite Movies',
                         subBullets: ['Inception, Goodwill Hunting, Howls Moving Castle, Donnie Darko, Interstellar, Dead Poets Society'],
                         },
                         {
                         label: 'Music I\'m Listening to',
                         subBullets: ['Artists: Rufus Du Sol, Bob Dylan, Frank Ocean, Sting, OG Kanye, Tyler the Creator, Bon Iver, Four Tet','Songs: Wish you Were Here, Englishman in New York, On my Knees, Nights, Runaway, Hey Ma, Two Thousand and Seventeen',
                         '<iframe width="560" height="315" src="https://www.youtube.com/embed/bdneye4pzMw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> <iframe width="560" height="315" src="https://www.youtube.com/embed/kjuPalIWNaM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> <iframe width="560" height="315" src="https://www.youtube.com/embed/HDAKS18Gv1U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'],
                         },

                        ];


  return (
    <div>
    <h1>
    _Life in Bits
    </h1>
      <Dropdown buttonName="Writing" options={options1}  />
      <Dropdown buttonName="Software" options={options2} />
      <Dropdown buttonName="Videos" options={options3} />
      <Dropdown buttonName="Digital Consumption" options={options4} />
      <img className="imgNYC" src={imageNYC} alt="bitsImage"/>
      <div className= "BsoSection">
      <h2> Building Something Old Newsletter </h2>
      <div className="substackiFrame">
      <iframe src="https://buildingsomethingold.substack.com/embed" ></iframe>
      </div>
      </div>
    </div>

  );
};

export default App;
