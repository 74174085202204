export default function Navbar(){
    return (
    <nav className="nav">
    <a href="/" className="tylerlasicki">Tyler_Lasicki</a>
    <ul>
        <li>
        <a className="linkedin" href="https://www.linkedin.com/in/tyler-lasicki/"> Linkedin </a>
        </li>
        <li>
        <a className="substack" href="https://buildingsomethingold.substack.com/"> Substack </a>
        </li>
        <li>
        <a className="twitter" href="https://twitter.com/tyler_lasicki"> Twitter </a>
        </li>
    </ul>
   </nav>
   )

}