import React, { useState, useEffect, useRef } from 'react';
import imageNYC from './images/Atoms/nycbridge.png';
import MapChart from './map';

const Dropdown = ({ buttonName, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        {buttonName}
        <p className= "plus">
                +
        </p>
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {options.map((option) => (
            <li className="label" key={option.label}>
              {option.label}
              {option.imgSrc && <img src={option.imgSrc} alt={option.label} />}
              {option.subBullets && (
                <ul>
                  {option.subBullets.map((subBullet) => (
                    <li className="subBullet" key={subBullet}>
                      {typeof subBullet === 'string' ? (
                        <span dangerouslySetInnerHTML={{ __html: subBullet }} />
                      ) : (
                        subBullet
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}

        </ul>
      )}
    </div>
  );
};

const App = () => {
  const options1 = [
    {
      label: 'Black Walnut Coffee Table',
      subBullets: ['Built a coffee table using recycled shelf boards as the base and a milled piece of black walnut.', 'The coffee table currently lives in Tribeca, NYC, with previous homes in Brooklyn, NYC, Greenwich Village, NYC, and Stamford, CT.'],
    },
    {
      label: 'Pair of Black Walnut Cutting Boards',
      subBullets: ['Both boards were constructed using four connected pieces of black walnut. Each board was finished with 15 coats of avocado oil.', 'One cutting board resides in Storrs, CT the other in Greenwich Village, NYC.'],
    },
    {
      label: 'Portable Mahogany Book Shelf',
      subBullets: ['Mahogany book shelf made with recycled boards. Gifted as a graduation present. Currently resides in Stamford, CT.' ],
    }
    ];
  const options2 = [
      {
       label: 'Where/ What I eat',
       subBullets: [<a href= "https://app.beliapp.com/lists/tylerrr"> Beli </a>],
            },
      {
        label: 'What I like to Make',
        subBullets: ['Ramen',
        'Spreads and Sauces: Hummus, Pesto, etc',
        'All things Breakfast: Shakshuka, Bagels, Chilli Egg Crispy Rice',
        'Coffee: Turkish + French press', 'Beer Battered Fish and Chips'],
      },

           ];

      const options3 = [
       {
        label: 'NYC Marathon 2023',
        subBullets: ['Ran the TCS NYC Marathon to help send 100 kids with serious illness to camp, through the amazing organization The Hole in the Wall Gang Camp. Together, we raised nearly <a href=https://give.holeinthewallgang.org/TylerLasicki> $265k <a/> FTK. My first marathon finish was an offical time of 3 hours and 34 min (8.11/mi). Unreal day/experience.', '<iframe class="strava-iframe" src="https://strava-embeds.com/activity/10167147423"></iframe>'],
        },
        {label: 'Latest Runs',
        subBullets: ['<iframe height="454" width="300" frameborder="0" allowtransparency="true" scrolling="no" src="https://www.strava.com/athletes/100753149/latest-rides/aaaac4e32124ac47b67375fc9d68166a9dca1806"></iframe>'
            ],},

            {
             label: 'Brooklyn Half 2022',
             subBullets: ['<iframe class="strava-iframe" src="https://strava-embeds.com/activity/7033642008"></iframe>'],
                  },
            {
              label: 'JPM Corporate Challenge 5k',
              subBullets: ['<iframe class="strava-iframe" src="https://strava-embeds.com/activity/7245580380"></iframe>'],
            },
            {
              label: 'Big Apple Half 2022',
              subBullets: [
              '<iframe class="strava-iframe" src="https://strava-embeds.com/activity/8236512556"></iframe>'
],
            },

            ];

            const options4 = [

                        {
                        label: 'NYC Pick Up Runs',
                        subBullets: ['From W4th to Brooklyn Bridge park--any nice day I can probably be found getting runs in somewhere in NYC'],
                        },
                        {
                        label: 'NYC Basketball League',
                         subBullets: ['2 years playing in a competitve NYC based mens league'],
                         },
                        {
                          label: 'Club Basketball at UConn',
                          subBullets: ['Traveled to play other east coast schools'],
                        },
                         {
                         label: 'HS Basketball at Westhill',
                         subBullets: ['Varsity Capitan + FCIAC Championship',
                                     <a href= "http://www.hudl.com/video/3/3872265/5721e19a9a91677e74b53393"> Junior Year Hightlights </a>                                        ],
                         },

                        ];
                        const options5 = [

                                                { label: '',
                                                subBullets: ['San Diego Surf Sesh 2024', '<iframe class="strava-iframe" src="https://strava-embeds.com/activity/11018204514"></iframe> '],
                                                },
                                                { label: '',
                                                    subBullets: ['Skiing Breck 2024', '<iframe class="strava-iframe" src="https://strava-embeds.com/activity/10863511778"></iframe> '],
                                                 },

                                                {
                                                   label: '',
                                                   subBullets: ['Climbing Mt Washington Jan 2024', '<iframe class="strava-iframe" src="https://strava-embeds.com/activity/10649454709"></iframe> '],
                                                 },
                                                 {
                                                   label: '',
                                                   subBullets: ['Climbing Mt Eisenhower & Pierce Jan 2024', '<iframe class="strava-iframe" src="https://strava-embeds.com/activity/10642343933"></iframe> '],
                                                 },

                                            {
                                            label: '',
                                            subBullets: ['Skiing in Telluride 2023', '<iframe class="strava-iframe" src="https://strava-embeds.com/activity/8601291840"></iframe>'],
                                             },

                                                {
                                                 label: '',
                                                 subBullets: ['Camping In Acadia National Park 2022'],
                                                      },
                                                {
                                                  label: '',
                                                  subBullets: ['Climbing Mt Washington in Winter 2022'],
                                                },
                                                {
                                                  label: '',
                                                  subBullets: ['Camping in Zion National Park 2021'],
                                                },

                                                {
                                                 label: '',
                                                 subBullets: ['East Coast Skiing circa 2008'],
                                                 },

                                                 {
                                                 label: '',
                                                 subBullets: ['Sky Diving in CT 2017'],
                                                  },


                                                ];

  const options6 = [
     {
       label: 'Favorite Books',
       subBullets: ['Dune, Zero to One, The Pillars of the Earth, Barbarians at the Gate, Benjamin Franklin An American Life, The Death and Life of Great American Cities, Design of Everyday Objects, Red Notice, Steve Jobs'],
       },

     ];

       const options7= [
          {
           label: <MapChart/>,
           subBullets: [] },
           /*{
            label: 'Study Abroad in London 2020 Covid x Brexit',
            subBullets: ['Cities Traveled Include: London, Wiltshire, Windsor, Amsterdam, Zaanse Schans, Glasgow, Edinburgh, Barcelona, Lisbon, and Sintra '],
            },
            {
            label: 'National/State Parks',
            subBullets: ['Zion, Acadia, Valley of Fire, Mt Washington, Mt Mansfield'],
            },
            {
            label: 'College Travels',
            subBullets: ['Playa Del Carmen, Tulum, Montreal, Ft Lauderdale, Mount Tremblant, Jay Peak, Las Vegas'],
            },
            {
            label: 'Work Related',
            subBullets: ['Washington D.C, Jupiter FL, Boston,MA, Chicago, IL'],
            },
            {
            label: 'Through HS Programs',
            subBullets: ['Toronto, Malibu, LA, Bloomington, Atlanta, Philly '],
            },
            {
            label: 'Recently',
            subBullets: ['Gdansk, Warsaw, Malbork, Zakynthos, Telluride'],
            },*/


          ];

  return (

    <div>
      <h1>
      _Life in Atoms
      </h1>
      <Dropdown buttonName="Woodcraft" options={options1} />
      <Dropdown buttonName="Food & Drink" options={options2} />
      <Dropdown buttonName="Running" options={options3} />
      <Dropdown buttonName="Basketball" options={options4} />
      <Dropdown buttonName="Outdoor Pursuits" options={options5} />
      <Dropdown buttonName="Books" options={options6} />
      <Dropdown buttonName="Travels" options={options7} />
      <img className= "imgNYC" src={imageNYC} alt="atomsImage"/>
      <div>
      <a className= "imgVSCO" href="http://vsco.co/tyler-uk?utm_source=user_grid&utm_medium=user_website&utm_campaign=link_to_grid"><img src="http://assets.vsco.co/assets/images/assets/Logo_white_48.png" alt="VSCO Logo" /></a>
      </div>
    </div>
  );
};

export default App;
