import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Annotation,
  ZoomableGroup
} from "react-simple-maps";

const MapChart = () => {
  return (
    <ComposableMap projection="geoOrthographic"
    projectionConfig={{rotate: [42.0, -8.0, 0], scale: 250 }}>


     <Geographies geography="https://observablehq.com/@d3/world-map"
      fill="#D6D6DA"
      stroke="#FFFFFF"
      strokeWidth={0.25}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} />
          ))
        }
      </Geographies>
      <Annotation
              subject={[-74, 40.7]}
              dx={70}
              dy={-10}
              connectorProps={{
                stroke: "#FF5533",
                strokeWidth: 1,
                strokeLinecap: "round"
              }} >
            <text fontSize="10" x="25" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                {"NYC"}
            </text>
      </Annotation>
      <Annotation
          subject={[0.13, 51.5]}
          dx={70}
          dy={-50}
          connectorProps={{
          stroke: "#FF5533",
          strokeWidth: 1,
          strokeLinecap: "round"
          }} >
          <text fontSize="10" x="40" textAnchor="end" alignmentBaseline="middle" fill="#F53">
          {"London"}
          </text>
      </Annotation>
      <Annotation
                subject={[4.9, 52.4]}
                dx={-40}
                dy={20}
                connectorProps={{
                stroke: "#FF5533",
                strokeWidth: 1,
                strokeLinecap: "round"
                }} >
                <text fontSize="10" x="" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                {"Amsterdam"}
                </text>
            </Annotation>
      <Annotation
                      subject={[-9.14, 38.7]}
                      dx={-5}
                      dy={30}
                      connectorProps={{
                      stroke: "#FF5533",
                      strokeWidth: 1,
                      strokeLinecap: "round"
                      }} >
                      <text fontSize="10" x="" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                      {"Lisbon & Sintra"}
                      </text>
                  </Annotation>
      <Annotation
                    subject={[1.99,51.35]}
                    dx={100}
                    dy={-30}
                    connectorProps={{
                      stroke: "#FF5533",
                      strokeWidth: 1,
                      strokeLinecap: "round"
                    }} >
                  <text fontSize="10" x="5" fill="#F53">
                      {"Wiltshire & Windsor"}
                  </text>
      </Annotation>
      <Annotation
                    subject={[4.25,55.86]}
                    dx={20}
                    dy={-50}
                    connectorProps={{
                      stroke: "#FF5533",
                      strokeWidth: 1,
                      strokeLinecap: "round"
                    }} >
                  <text fontSize="10" x="5" fill="#F53">
                      {"Glasgow & Edinburgh"}
                  </text>
        </Annotation>
        <Annotation
                           subject={[2.16,41.3]}
                            dx={-50}
                            dy={10}
                            connectorProps={{
                              stroke: "#FF5533",
                              strokeWidth: 1,
                              strokeLinecap: "round"
                            }} >
                          <text fontSize="10" x="-50" fill="#F53">
                              {"Barcelona"}
                          </text>
                </Annotation>
        <Annotation
                                   subject={[-113,37]}
                                    dx={-120}
                                    dy={-120}
                                    connectorProps={{
                                      stroke: "#FF5533",
                                      strokeWidth: 1,
                                      strokeLinecap: "round"
                                    }} >
                                  <text fontSize="10" x="5" fill="#F53">
                                   {"Zion National Park and Valley of Fire"}
                                  </text>
                        </Annotation>
          <Annotation
                                           subject={[-71,42]}
                                            dx={50}
                                            dy={-20}
                                            connectorProps={{
                                              stroke: "#FF5533",
                                              strokeWidth: 1,
                                              strokeLinecap: "round"
                                            }} >
                                          <text fontSize="9" x="10" fill="#F53">
                                           {"Boston"}
                                          </text>
                                </Annotation>
          <Annotation
                                subject={[-71,44]}
                                dx={100}
                                dy={-100}
                                connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                                }} >
                                <text fontSize="10" x="-5" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {"Mt Washington"}
                                </text>
                            </Annotation>
          <Annotation
                                          subject={[-77,39]}
                                          dx={50}
                                          dy={15}
                                          connectorProps={{
                                          stroke: "#FF5533",
                                          strokeWidth: 1,
                                          strokeLinecap: "round"
                                          }} >
                                          <text fontSize="10" x="20" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                          {"DC"}
                                          </text>
                                      </Annotation>
          <Annotation
                                          subject={[-87,20]}
                                          dx={-90}
                                          dy={20}
                                          connectorProps={{
                                          stroke: "#FF5533",
                                          strokeWidth: 1,
                                          strokeLinecap: "round"
                                          }} >
                                          <text fontSize="9" x="-5" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                          {"Tulum & Playa Del Carmen"}
                                          </text>
                                      </Annotation>
          <Annotation
                                                    subject={[-87,41]}
                                                    dx={-10}
                                                    dy={-7}
                                                    connectorProps={{
                                                    stroke: "#FF5533",
                                                    strokeWidth: 1,
                                                    strokeLinecap: "round"
                                                    }} >
                                                    <text fontSize="9" x="-5" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                                    {"Chicago"}
                                                    </text>
                                                </Annotation>
           <Annotation
                                                              subject={[-107,37]}
                                                              dx={-90}
                                                              dy={20}
                                                              connectorProps={{
                                                              stroke: "#FF5533",
                                                              strokeWidth: 1,
                                                              strokeLinecap: "round"
                                                              }} >
                                                              <text fontSize="10" x="-5" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                                              {"Telluride"}
                                                              </text>
                                                          </Annotation>
            <Annotation
                            subject={[18.6, 54]}
                            dx={90}
                            dy={10}
                            connectorProps={{
                            stroke: "#FF5533",
                            strokeWidth: 1,
                            strokeLinecap: "round"
                            }} >
                            <text fontSize="9" x="5" fill="#F53">
                            {"Gdansk, Warsaw, Malbork, & Musria"}
                            </text>
                        </Annotation>
            <Annotation
                                        subject={[20.8, 37.8]}
                                        dx={80}
                                        dy={-10}
                                        connectorProps={{
                                        stroke: "#FF5533",
                                        strokeWidth: 1,
                                        strokeLinecap: "round"
                                        }} >
                                        <text fontSize="10" x="60" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                        {"Zakynthos"}
                                        </text>
                                    </Annotation>
            <Annotation
                                        subject={[-84,34]}
                                        dx={30}
                                        dy={30}
                                        connectorProps={{
                                        stroke: "#FF5533",
                                        strokeWidth: 1,
                                        strokeLinecap: "round"
                                        }} >
                                        <text fontSize="10" x="40" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                        {"Atlanta"}
                                        </text>
                                    </Annotation>
            <Annotation
                                        subject={[-68, 44]}
                                        dx={50}
                                        dy={-40}
                                        connectorProps={{
                                        stroke: "#FF5533",
                                        strokeWidth: 1,
                                        strokeLinecap: "round"
                                        }} >
                                        <text fontSize="9" x="5"  alignmentBaseline="right" fill="#F53">
                                        {"Acadia & Bar Harbor"}
                                        </text>
                                    </Annotation>
            <Annotation
                                                    subject={[-118, 34]}
                                                    dx={-120}
                                                    dy={-50}
                                                    connectorProps={{
                                                    stroke: "#FF5533",
                                                    strokeWidth: 1,
                                                    strokeLinecap: "round"
                                                    }} >
                                                    <text fontSize="10" x="-5" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                                    {"LA & Malibu"}
                                                    </text>
                                                </Annotation>

            <Annotation
                                                                subject={[-75, 35]}
                                                                dx={40}
                                                                dy={15}
                                                                connectorProps={{
                                                                stroke: "#FF5533",
                                                                strokeWidth: 1,
                                                                strokeLinecap: "round"
                                                                }} >
                                                                <text fontSize="10" x="30"textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                                                {"Outter Banks"}
                                                                </text>
                                                            </Annotation>
            <Annotation
                                        subject={[-70,18.7]}
                                        dx={30}
                                        dy={10}
                                        connectorProps={{
                                        stroke: "#FF5533",
                                        strokeWidth: 1,
                                        strokeLinecap: "round"
                                        }} >
                                        <text fontSize="10" fill="#F53">
                                        {"Punta Cana"}
                                        </text>
                                    </Annotation>
            <Annotation
                                                    subject={[-80.1,26.1]}
                                                    dx={30}
                                                    dy={20}
                                                    connectorProps={{
                                                    stroke: "#FF5533",
                                                    strokeWidth: 1,
                                                    strokeLinecap: "round"
                                                    }} >
                                                    <text fontSize="10" fill="#F53">
                                                    {"Ft Lauderdale"}
                                                    </text>
                                                </Annotation>
            <Annotation
                                                                subject={[-115.1,36.1]}
                                                                dx={-90}
                                                                dy={-40}
                                                                connectorProps={{
                                                                stroke: "#FF5533",
                                                                strokeWidth: 1,
                                                                strokeLinecap: "round"
                                                                }} >
                                                                <text fontSize="10" x="-5" fill="#F53">
                                                                {"Las Vegas"}
                                                                </text>
                                                            </Annotation>
           <Annotation
                                                                           subject={[-75.1,39.9]}
                                                                           dx={70}
                                                                           dy={5}
                                                                           connectorProps={{
                                                                           stroke: "#FF5533",
                                                                           strokeWidth: 1,
                                                                           strokeLinecap: "round"
                                                                           }} >
                                                                           <text fontSize="10" x="" fill="#F53">
                                                                           {"Philly"}
                                                                           </text>
                                                                       </Annotation>
           <Annotation
                                                   subject={[-72, 44.9]}
                                                   dx={50}
                                                   dy={-22.5}
                                                   connectorProps={{
                                                   stroke: "#FF5533",
                                                   strokeWidth: 1,
                                                   strokeLinecap: "round"
                                                   }} >
                                                   <text fontSize="8" x="5"  alignmentBaseline="right" fill="#F53">
                                                   {"Most Ski Mountains in VT"}
                                                   </text>
                                               </Annotation>

           <Annotation
                                              subject={[-75,46]}
                                               dx={-65}
                                               dy={-85}
                                               connectorProps={{
                                                 stroke: "#FF5533",
                                                 strokeWidth: 1,
                                                 strokeLinecap: "round"
                                               }} >
                                             <text fontSize="10" x="5" fill="#F53">
                                              {"Mt Tremblant"}
                                             </text>
                                   </Annotation>
           <Annotation
                                                               subject={[-79.38,43.65]}
                                                               dx={-10}
                                                               dy={-10}
                                                               connectorProps={{
                                                               stroke: "#FF5533",
                                                               strokeWidth: 1,
                                                               strokeLinecap: "round"
                                                               }} >
                                                               <text fontSize="9" x="-5" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                                               {"Toronto"}
                                                               </text>
                                                           </Annotation>
            <Annotation
                                                                          subject={[-80,26.93]}
                                                                          dx={-10}
                                                                          dy={-2}
                                                                          connectorProps={{
                                                                          stroke: "#FF5533",
                                                                          strokeWidth: 1,
                                                                          strokeLinecap: "round"
                                                                          }} >
                                                                          <text fontSize="9" x="" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                                                          {"Jupiter"}
                                                                          </text>
                                                                      </Annotation>

    </ComposableMap>

  );
};


export default MapChart;
