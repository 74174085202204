import homeImage from './images/homepage/constitution.jpg';
import MapChart from './HomeMap';

export default function Home(){
return(
<homep className="homep">
<div class="container">
<h1>
_Building something new in New York City + writing 'Building Something Old'.
</h1>
 <img src={homeImage} alt="home Image" class="image-home" />
 </div>

    <ul>
        <li>
        <a href= "/bits"> <button> _bits  </button> </a>
        </li>
        <li>
        <a href= "/atoms"> <button> _atoms </button> </a>
        </li>
    </ul>


<div>
            <p class="footerNote"> Made in React by Tyler w/ love</p>
            </div>

</homep>
)}