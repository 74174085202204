import './App.css';
import Bits from './Components/bits';
import Atoms from './Components/atoms';
import Nav_bar from './Components/nav_bar';
import Home from './Components/home';
import {Route, Routes} from "react-router-dom";
import Mapchart from './Components/map';

function App() {
        return(
        <div class="container">
            <Nav_bar/>
            <Routes>
                <Route path= "/" element = {<Home/>}/>
                <Route path= "/bits" element = {<Bits/>}/>
                <Route path= "/atoms" element = {<Atoms/>}/>
            </Routes>


</div>
    );
    }
export default App;